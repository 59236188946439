import React, { useEffect } from "react";
// import '@shopify/polaris/build/esm/styles.css';
import enTranslations from "@shopify/polaris/locales/en.json";
import { AppProvider } from "@shopify/polaris";
import { ShopStore } from "./store/PullStore";
import store from "store";
import "./App.css";
import Scripts from "./components/Scripts";

function App() {
  //

  useEffect(() => {
    store.clearAll();
    const URL = window.location.pathname;
    // const URL = "https://qrite.app/272/4579387670621/email";

    const params = URL.split("/");
    console.log(params);
    if (params) {
      ShopStore.update((s) => {
        s.MerchantID = params[1];
        s.Channel = params[3];
        s.OrderID = params[2];
      });
      localStorage.setItem("merchantID", params[1]);
      localStorage.setItem("orderID", params[2]);
      localStorage.setItem("channel", params[3]);
      // navigate('/scripts');
    }
  }, []);
  return (
    <AppProvider i18n={enTranslations}>
      <div className="App">
        <Scripts />
      </div>
    </AppProvider>
  );
}

export default App;
