import React, { useEffect, useState } from "react";
import { Stack } from "@shopify/polaris";
import Type11 from "../scripttags/Type11";
import Type12 from "../scripttags/Type12";
import Type21 from "../scripttags/Type21";
import Type22 from "../scripttags/Type22";
import store from "store";
import apiCaller from "../helper/apiCaller";

const Scripts = () => {
  const [question, setQuestion] = useState("");
  const [type, setType] = useState("");
  const [openText, setOpenText] = useState("");
  const [openTextQuestion, setOpenTextQuestion] = useState("");
  const [url, setUrl] = useState("");

  const scripts =
    type === "customer_satisfaction_score" && openText === true
      ? 11
      : type === "customer_satisfaction_score" && openText === false
      ? 12
      : type === "net_promoter_score" && openText === true
      ? 21
      : type === "net_promoter_score" && openText === false
      ? 22
      : "";
  async function getLogoUrl() {
    const URL = "getLogoUrl";
    const headers = { "Access-Control-Allow-Origin": "*" };
    const m_id = localStorage.getItem("merchantID");
    const data = {
      merchant_id: parseInt(m_id),
      format: "jpg",
      is_upload: false,
    };
    await apiCaller(URL, headers, data).then((response) => {
      const url = response.data;
      setUrl(url);
    });
  }

  async function getFeebackQuestions() {
    const URL = "getFeedbackQuestions";
    const merchant_id = localStorage.getItem("merchantID");
    const headers = { "Access-Control-Allow-Origin": "*" };
    const data = {
      merchant_id: parseInt(merchant_id),
    };
    await apiCaller(URL, headers, data).then((response) => {
      const DATA = response.data.data[0];
      const SHOWOPENTEXT = DATA.show_open_text;
      const Question = DATA.questions[0];
      const OpenQuestion = DATA.questions[1];
      const TYPE = DATA.type_of_question;
      setQuestion(Question);
      setType(TYPE);
      setOpenText(SHOWOPENTEXT);
      setOpenTextQuestion(OpenQuestion);
      // setUrl(URL);

      localStorage.setItem("show_open_text", SHOWOPENTEXT);
      localStorage.setItem("type_of_question", TYPE);
    });
  }
  useEffect(() => {}, [question, openText, type, openTextQuestion, url]);
  useEffect(() => {
    getLogoUrl();
  }, []);
  useEffect(() => {
    getFeebackQuestions();
  }, []);
  return (
    <div>
      <Stack alignment="center" distribution="center">
        <img
          src={url}
          alt="logo"
          style={{ marginBottom: "50px", width: "150px" }}
        />
      </Stack>
      {scripts === 11 ? (
        <Type11 question={question} openTextQuestion={openTextQuestion} />
      ) : scripts === 12 ? (
        <Type12 question={question} />
      ) : scripts === 21 ? (
        <Type21 question={question} openTextQuestion={openTextQuestion} />
      ) : scripts === 22 ? (
        <Type22 question={question} />
      ) : (
        ""
      )}
      <Stack alignment="center" distribution="center">
        <img
          src="https://qrite.com/app/themes/qrite/dist/images/logo-text.png?id=338d89c5b900df6da0b2"
          alt="logo"
          style={{ marginTop: "50px", width: "150px" }}
        />
      </Stack>
    </div>
  );
};

export default Scripts;
