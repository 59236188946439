import React, { useState, useEffect } from "react";
import { Page, Stack, EmptyState } from "@shopify/polaris";
import HeaderStar from "../assets/emogis/Stars/StarHeader.svg";
import store from "store";
import StarA from "../assets/emogis/Stars/StarA.svg";
import StarB from "../assets/emogis/Stars/StarB.svg";
import StarC from "../assets/emogis/Stars/StarC.svg";
import Heart from "../assets/emogis/Heart.svg";
import "../styles/textStyles.css";
import "../styles/npsStyles.css";
import apiCaller from "../helper/apiCaller";
import FeedbackFail from "../components/FeedbackFail";
import Angry from "../assets/emogis/smilies/angry.svg";
import Happy from "../assets/emogis/smilies/happy.svg";

const Type22 = ({ question }) => {
  const [nps, setNps] = useState("");
  const [fail, setFail] = useState(false);
  const [toggle, setToggle] = useState(false);
  const [reward, setReward] = useState({ code: "", text: "" });
  const feedbackQuestion = question;
  const buttonData = [
    {
      id: "btn-0",
      value: "0",
    },
    {
      id: "btn-1",
      value: "1",
    },
    {
      id: "btn-2",
      value: "2",
    },
    {
      id: "btn-3",
      value: "3",
    },
    {
      id: "btn-4",
      value: "4",
    },
    {
      id: "btn-5",
      value: "5",
    },
    {
      id: "btn-6",
      value: "6",
    },
    {
      id: "btn-7",
      value: "7",
    },
    {
      id: "btn-8",
      value: "8",
    },
    {
      id: "btn-9",
      value: "9",
    },
    {
      id: "btn-10",
      value: "10",
    },
  ];

  const handleClick = async (value) => {
    const orderID = localStorage.getItem("orderID");
    const channel = localStorage.getItem("channel");
    setNps(value);
    const URL = "saveFeedback";
    const headers = { "Access-Control-Allow-Origin": "*" };
    const data = {
      shopify_order_id: parseInt(orderID),
      feedback: {
        type: "net_promoter_score",
        question: question,
        value: parseInt(value),
        open_text: "",
        open_text_question: "",
        channel,
      },
    };

    try {
      await apiCaller(URL, headers, data).then((response) => {
        if (response.data.rewardFeedback) {
          const rewardCode = response.data.rewardFeedback.code;
          const rewardText = response.data.rewardFeedback.text;
          setReward({ code: rewardCode, text: rewardText });
        }
      });

      // console.log(nps);
      setToggle(!toggle);
    } catch (error) {
      setFail(!fail);
    }
  };
  useEffect(() => {}, [reward]);
  useEffect(() => {
    localStorage.setItem("score21", nps);
  }, [nps]);
  return (
    <>
      {!fail ? (
        <Page narrowWidth>
          {toggle === false ? (
            <div id="main-box">
              <Stack vertical alignment="center">
                <img
                  src={HeaderStar}
                  style={{ width: " 100px" }}
                  alt="star-logo"
                  className="animate__animated animate__fadeInDownBig"
                />

                {/* <h1> Give us feedback </h1> */}

                {/* <h3>{rewardText}</h3> */}
                <div style={{ padding: "0px 20px", lineHeight: "normal" }}>
                  <h3>{feedbackQuestion}</h3>
                </div>
                <Stack distribution="center">
                  <img src={Angry} alt="emogi" />
                  {buttonData.map((items) => (
                    <button
                      id={items.id}
                      // style={{ fontSize: '0px' }}
                      value={items.value}
                      onClick={(e) => handleClick(e.target.value)}
                    >
                      click
                    </button>
                  ))}
                  <img src={Happy} alt="emogi" />
                </Stack>
              </Stack>
              <div id="stars-box">
                <img
                  className="animate__animated animate__fadeInBottomLeft"
                  src={StarA}
                  alt="stars"
                />
                <div style={{ position: "relative", textAlign: "center" }}>
                  <img
                    src={StarB}
                    className="animate__animated animate__fadeInBottomRight"
                    alt="stars"
                  />
                  <p
                    style={{
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      transform: "translate(-50%, -50%)",
                      fontSize: "10px",
                    }}
                    // style='font-size: 12px;'
                  >
                    powered by{" "}
                    <a href="http://qrite.com" target="_blank" rel="noreferrer">
                      Qrite
                    </a>
                  </p>
                </div>
              </div>
            </div>
          ) : toggle === true ? (
            <div id="thankyou-box">
              {/* <Card sectioned> */}
              <img
                src={StarC}
                alt="Star"
                style={{ marginLeft: "20%" }}
                className="animate__animated animate__fadeInUpBig"
              />
              <EmptyState image={Heart}>
                <div style={{ marginTop: "30px" }}>
                  <h1 className="animate__animated animate__fadeInDownBig">
                    Thank You
                  </h1>
                  <p className="animate__animated animate__fadeInUpBig">
                    powered by Qrite
                  </p>
                </div>
                <div
                  style={{
                    marginTop: "30px",
                    lineHeight: "normal",
                    textAlign: "center",
                  }}
                >
                  <h1 className="animate__animated animate__fadeInDownBig">
                    {reward.text}
                  </h1>
                  <p className="animate__animated animate__fadeInUpBig">
                    {reward.code}
                  </p>
                </div>
              </EmptyState>
              {/* </Card> */}
              <div id="thankyoustar-box">
                <img src={StarA} alt="stars" />
                <div style={{ position: "relative", textAlign: "center" }}>
                  <img
                    src={StarB}
                    className="animate__animated animate__fadeInBottomRight"
                    alt="stars"
                  />
                  <p
                    style={{
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      transform: "translate(-50%, -50%)",
                      fontSize: "10px",
                    }}
                    // style='font-size: 12px;'
                  >
                    powered by{" "}
                    <a href="http://qrite.com" target="_blank" rel="noreferrer">
                      Qrite
                    </a>
                  </p>
                </div>
              </div>
            </div>
          ) : (
            ""
          )}

          {/* <p>customer satisfaction survey powered by qrite.com</p> */}
        </Page>
      ) : (
        <FeedbackFail />
      )}
    </>
  );
};

export default Type22;
