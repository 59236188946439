import React from 'react';
import infoimage from '../assets/infoimage.jpg';
import { Page, EmptyState, Heading } from '@shopify/polaris';

const FeedbackFail = () => {
  return (
    <Page narrowWidth>
      <EmptyState>
        <img src={infoimage} alt='infoimage' style={{ width: '40%' }} />
        <Heading>We have already recieved feedback for this Product.</Heading>
      </EmptyState>
    </Page>
  );
};

export default FeedbackFail;
