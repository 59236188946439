import axios from 'axios';
const REACT_APP_BASE_URL = 'https://j8u5vbjvuj.execute-api.eu-central-1.amazonaws.com/prod/';
export default function apiCaller(endPoint, headers, data) {
  const url = `${REACT_APP_BASE_URL}${endPoint}`;
  return axios({
    method: "post",
    url,
    headers,
    data,
  });
}
