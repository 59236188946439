import React, { useState, useEffect, useCallback } from "react";
import { TextField, Page, Stack, EmptyState } from "@shopify/polaris";
import HeaderStar from "../assets/emogis/Stars/StarHeader.svg";
import store from "store";
import StarA from "../assets/emogis/Stars/StarA.svg";
import StarB from "../assets/emogis/Stars/StarB.svg";
import StarC from "../assets/emogis/Stars/StarC.svg";
import Heart from "../assets/emogis/Heart.svg";
import "../styles/textStyles.css";
import "../styles/npsStyles.css";
import apiCaller from "../helper/apiCaller";
import FeedbackFail from "../components/FeedbackFail";
import Angry from "../assets/emogis/smilies/angry.svg";
import Happy from "../assets/emogis/smilies/happy.svg";

const Type21 = ({ question, openTextQuestion }) => {
  const [nps, setNps] = useState("");
  const [toggle, setToggle] = useState(false);
  const [thanks, setThanks] = useState(false);
  const [fail, setfail] = useState(false);
  const [reward, setReward] = useState({ code: "", text: "" });
  const [textField, setTextField] = useState("");
  const feedbackQuestion = question;
  const OpenTextQuestion = openTextQuestion;
  const handleTextFieldChange = useCallback(
    (newValue) => setTextField(newValue),
    []
  );

  const handleSubmit = async () => {
    if (textField === "") {
      alert("Please provide a reason");
    } else {
      const orderID = localStorage.getItem("orderID");
      const channel = localStorage.getItem("channel");
      // setNps(value);
      const URL = "saveFeedback";
      const headers = { "Access-Control-Allow-Origin": "*" };
      const data = {
        shopify_order_id: parseInt(orderID),
        feedback: {
          type: "net_promoter_score",
          question: question,
          value: parseInt(nps),
          open_text: textField,
          open_text_question:
            OpenTextQuestion === undefined ? "" : OpenTextQuestion,
          channel,
        },
      };
      await apiCaller(URL, headers, data);

      setThanks(!thanks);
    }
  };

  const handleCancel = () => {
    setToggle(!toggle);
  };
  const buttonData = [
    {
      id: "btn-0",
      value: "0",
    },
    {
      id: "btn-1",
      value: "1",
    },
    {
      id: "btn-2",
      value: "2",
    },
    {
      id: "btn-3",
      value: "3",
    },
    {
      id: "btn-4",
      value: "4",
    },
    {
      id: "btn-5",
      value: "5",
    },
    {
      id: "btn-6",
      value: "6",
    },
    {
      id: "btn-7",
      value: "7",
    },
    {
      id: "btn-8",
      value: "8",
    },
    {
      id: "btn-9",
      value: "9",
    },
    {
      id: "btn-10",
      value: "10",
    },
  ];

  const handleClick = async (value) => {
    const orderID = localStorage.getItem("orderID");
    const channel = localStorage.getItem("channel");
    setNps(value);
    const URL = "saveFeedback";
    const headers = {};
    const data = {
      shopify_order_id: parseInt(orderID),
      feedback: {
        type: "net_promoter_score",
        question: question,
        value: parseInt(value),
        open_text: "",
        open_text_question: "",
        channel,
      },
    };
    try {
      await apiCaller(URL, headers, data).then((response) => {
        console.log(response);
        if (response.data.rewardFeedback) {
          const rewardCode = response.data.rewardFeedback.code;
          const rewardText = response.data.rewardFeedback.text;
          setReward({ code: rewardCode, text: rewardText });
        }
      });
      setToggle(!toggle);
    } catch (error) {
      setfail(!fail);
    }
  };
  useEffect(() => {}, [reward, nps]);
  useEffect(() => {
    localStorage.setItem("score21", nps);
  }, [nps]);
  return (
    <>
      {!fail ? (
        <Page narrowWidth>
          {toggle === false && thanks === false ? (
            <div id="main-box">
              <Stack vertical alignment="center">
                <img
                  src={HeaderStar}
                  style={{ width: " 100px" }}
                  alt="star-logo"
                  className="animate__animated animate__fadeInDownBig"
                />

                <div style={{ padding: "0px 20px", lineHeight: "normal" }}>
                  <h3>{feedbackQuestion}</h3>
                </div>
                <Stack distribution="center">
                  <img src={Angry} alt="emogi" />
                  {buttonData.map((items) => (
                    <button
                      id={items.id}
                      // style={{ fontSize: '0px' }}
                      value={items.value}
                      onClick={(e) => handleClick(e.target.value)}
                    >
                      click
                    </button>
                  ))}
                  <img src={Happy} alt="emogi" />
                </Stack>
              </Stack>
              <div id="stars-box">
                <img
                  className="animate__animated animate__fadeInBottomLeft"
                  src={StarA}
                  alt="stars"
                />
                <div style={{ position: "relative", textAlign: "center" }}>
                  <img
                    src={StarB}
                    className="animate__animated animate__fadeInBottomRight"
                    alt="stars"
                  />
                  <p
                    style={{
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      transform: "translate(-50%, -50%)",
                      fontSize: "10px",
                    }}
                    // style='font-size: 12px;'
                  >
                    powered by{" "}
                    <a href="http://qrite.com" target="_blank" rel="noreferrer">
                      Qrite
                    </a>
                  </p>
                </div>
              </div>
            </div>
          ) : toggle === true && thanks === false ? (
            <div id="comment-box">
              <Stack vertical alignment="center">
                <div id="tagline-box">
                  <Stack distribution="baseline">
                    <img
                      src={HeaderStar}
                      style={{ width: " 30px" }}
                      alt="star-logo"
                      className="animate__animated animate__backInRight"
                    />
                    <div id="tagline">
                      <h3>{OpenTextQuestion}</h3>
                    </div>
                  </Stack>
                </div>
              </Stack>
              <div
                id="textarea-box"
                className="animate__animated  animate__zoomIn"
              >
                <TextField
                  placeholder="Add your comments here (optional)"
                  value={textField}
                  onChange={handleTextFieldChange}
                  multiline={4}
                />
              </div>
              <div style={{ marginTop: "20px" }}>
                <Stack distribution="center" alignment="center">
                  <button
                    id="btn-primary"
                    className="animate__animated  animate__backInLeft"
                    onClick={handleCancel}
                  >
                    Cancel
                  </button>
                  <button
                    id="btn-secondary"
                    className="animate__animated  animate__backInRight"
                    onClick={handleSubmit}
                  >
                    Submit
                  </button>
                </Stack>
              </div>
              <div id="stars-box">
                <img
                  className="animate__animated animate__fadeInBottomLeft"
                  src={StarA}
                  alt="stars"
                />
                <div style={{ position: "relative", textAlign: "center" }}>
                  <img
                    src={StarB}
                    className="animate__animated animate__fadeInBottomRight"
                    alt="stars"
                  />
                  <p
                    style={{
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      transform: "translate(-50%, -50%)",
                      fontSize: "10px",
                    }}
                    // style='font-size: 12px;'
                  >
                    powered by{" "}
                    <a href="http://qrite.com" target="_blank" rel="noreferrer">
                      Qrite
                    </a>
                  </p>
                </div>
              </div>
            </div>
          ) : toggle === true && thanks === true && textField !== "" ? (
            <div id="thankyou-box">
              {/* <Card sectioned> */}
              <img
                src={StarC}
                alt="Star"
                style={{ marginLeft: "20%" }}
                className="animate__animated animate__fadeInUpBig"
              />
              <EmptyState image={Heart}>
                <div style={{ marginTop: "30px" }}>
                  <h1 className="animate__animated animate__fadeInDownBig">
                    Thank You
                  </h1>
                  <p className="animate__animated animate__fadeInUpBig">
                    powered by Qrite
                  </p>
                </div>
                <div
                  style={{
                    marginTop: "30px",
                    lineHeight: "normal",
                    textAlign: "center",
                  }}
                >
                  <h1 className="animate__animated animate__fadeInDownBig">
                    {reward.text}
                  </h1>
                  <p className="animate__animated animate__fadeInUpBig">
                    {reward.code}
                  </p>
                </div>
              </EmptyState>
              {/* </Card> */}
              <div id="thankyoustar-box">
                <img src={StarA} alt="stars" />
                <div style={{ position: "relative", textAlign: "center" }}>
                  <img
                    src={StarB}
                    className="animate__animated animate__fadeInBottomRight"
                    alt="stars"
                  />
                  <p
                    style={{
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      transform: "translate(-50%, -50%)",
                      fontSize: "10px",
                    }}
                    // style='font-size: 12px;'
                  >
                    powered by{" "}
                    <a href="http://qrite.com" target="_blank" rel="noreferrer">
                      Qrite
                    </a>
                  </p>
                </div>
              </div>
            </div>
          ) : (
            ""
          )}
        </Page>
      ) : (
        <FeedbackFail />
      )}
    </>
  );
};

export default Type21;
